import styled from "styled-components";
import { Button, Space } from "antd";

export const StyledSpace = styled(Space)`
  display: flex;
  gap: 15px;

  position: fixed;
  top: 15px;
  left: 20px;

  z-index: 99;
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
`;

export const StyledButtonBack = styled(Button)`
  border-radius: 100%;

  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButtonLogout = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
