// store/print/reducers.js
import { SET_PRINT_DATA, SET_PRINT_SIZE } from './actions';

const initialState = {
  printData: [],
  printSize: []
};

const printReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRINT_DATA:
      return {
        ...state,
        printData: action.payload,
      };
      case SET_PRINT_SIZE:
        return{
          ...state,
          printSize: action.payload
        }
    default:
      return state;
  }
};

export default printReducer;
