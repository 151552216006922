import React from "react";
import * as S from "../../styles";
import { Checkbox } from "antd";
import GuestStatus from "./GuestStatus";
import ActionGrid from "./components/ActionGrid";
import { PhoneFilled } from "@ant-design/icons";
const GuestCard = ({ guest, onChangeCheck, print, showButton }) => {

  

  return (
    <S.GuestCardContainer>
      <S.GuestCardCheckbox>
        <Checkbox
          onChange={onChangeCheck}
          value={guest.id}
        />
      </S.GuestCardCheckbox>
      <S.GuestCardSection>
      <S.GuestCardName>{guest.name}</S.GuestCardName>
      <S.GuestCardNumber>{guest.telephone ? <PhoneFilled style={{marginRight:'5px'}} />:<PhoneFilled style={{marginRight:'5px', color:"transparent"}} />}{guest.telephone}</S.GuestCardNumber>
      <S.footerCardContainer>
        <ActionGrid guest={guest} print={print} showButton={showButton} /> <GuestStatus guest={guest} />
      </S.footerCardContainer>
      </S.GuestCardSection>
    </S.GuestCardContainer>
  );
};

export default GuestCard;
