import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as S from "./styles";
import GuestGrid from "./components/Guest/GuestGrid";
import { useSelector } from "react-redux";

const EventDetail = ({credencial, setCredencial}) => {
  const [detail, setDetail] = useState();
  let params = useParams();
  let location = useLocation();
  const eventData = useSelector((state) => state?.eventos?.eventData);


  function encontrarObjetoComId(objetoPrincipal, id) {
    for (const categoria in objetoPrincipal) {
      const arrayObjetos = objetoPrincipal[categoria];
      const objetoEncontrado = arrayObjetos.find(
        (objeto) => objeto.id.toString() === id
      );

      if (objetoEncontrado) {
        return objetoEncontrado;
      }
    }
    return null;
  }

  useEffect(() => {
    let allEvents = location?.state?.allEventsList;
    if(!location?.state?.allEventsList){
      allEvents = eventData;
    }
    setDetail(encontrarObjetoComId(allEvents, params.id));
  }, [params, eventData, location?.state?.allEventsList]);

  const formatData = (data) => {
    const [ano, mes, dia] = data.split("-");

    return `${dia}/${mes}/${ano}`
  }

  return (
    detail && (
      <S.EventContainer>
        <S.EventSection>
          <S.EventBanner src={detail.front_cover} />
          <S.EventInfo>{detail.nameCategory} - {detail.name} - {formatData(detail.date)}</S.EventInfo>
          <GuestGrid event={detail} credencial={credencial} setCredencial={setCredencial}/>
        </S.EventSection>
      </S.EventContainer>
    )
  );
};

export default EventDetail;
