import styled from "styled-components";
import { defaultTheme as theme } from "../../styles/theme";
import { Button, Input } from "antd";
import { PieChart } from "recharts";

const windowWidth = window.innerWidth;

const Slide = styled.div`
  margin-right: 20px;
  ${({ blocked }) => blocked !== null && "opacity: 0.5"}
  background-color: #FFF;
  padding: 15px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 200px;
  margin-bottom: ${theme.spacings.medium};
  flex-wrap: nowrap;
  overflow-y: auto;
  padding-bottom: 20px;

  @media only screen and (max-width: 600px) {
    max-height: fit-content;
    gap: 25px;
    margin-bottom: 50px;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  width: 200px;
  height: 100px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

const Heart = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: ${theme.colors.white};
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  border: 0;
`;

const HeadingContainer = styled.div`
  max-width: 200px;
`;

const EventInfos = styled.p`
  color: ${theme.colors.gray};
  font-size: ${theme.font.sizes.xxsmall};
  margin-top: 6px;
  display: flex;
  max-width: 200px;
`;

const ButtonScroll = styled.button`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 0px;
  border-radius: 5px;
  margin-top: 20px;
  ${({ position }) => (position === "left" ? "left:10px" : "right:20px")}
`;

export const EventHeader = styled.div`
  flex-direction: row;
  align-items: center;
  ${windowWidth < 650 && "justify-content: space-between;"}
  margin-bottom: 20px;
  padding-left: ${"0rem"};

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    padding: 0
  }

`;

export const GalleryContainer = styled.div``;

export const EventContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const EventSection = styled.div`
  margin-top: 3vh;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
  width: 80%;
  min-height: 85vh;

  border-radius: 18px;

  background-color: #f6f6f6;

  padding: 20px 20px;

  margin-bottom: 40px;

  @media only screen and (max-width: 600px) {
    margin-top: 0vh;
    width: 100%;
    max-width: 100%;
    box-shadow: transparent;
    box-shadow: rgba(99, 99, 99, 0) 0px 2px 8px 0px;
    background-color: transparent;

  }
`;

export const EventBanner = styled.img`
  width: 100%;
  height: 300px;

  object-fit: cover;
  object-position: center;

  border-radius: 18px;
`;

export const EventInfo = styled.div``;

export const GuestContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-flow: column wrap;
  flex-shrink: 0;
`;

export const GuestContainerSection = styled.div`
  margin-top: 25px;
  /* margin-bottom: 25px; */
  flex: 1 1 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;

  //width: 100%;

  padding: 15px;

  background-color: #eaeaea;

  border-radius: 8px;

  @media only screen and (max-width: 600px) {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
    max-width: 100%;

    .order-menu{
      font-size: larger;
    }

  }
`;

export const OptionsGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FilterContainer = styled.div`
  width: 50%;
  max-width: 50%;
  
  @media only screen and (max-width: 600px) {
    width: 70%;
    max-width: 70%;

    .radio-group-comp{
      display: flex;
      flex-wrap: wrap;
      justify-content: end !important;
      position: relative;
      left: 25px;
    }
    .radio-child{
      min-width: 106px;
    }
  }
`;

export const CheckContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const GuestCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 10px;

  width: 300px;
  //height: 50px;

  background-color: #fff;

  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const GuestCardSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: auto;
  /* gap: 10px; */
`;

export const GuestCardCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4%;
  max-width: 4%;
  align-self: stretch;

  padding-left: 5px;
  padding-right: 10px;
  margin-right: 10px;

  border-right: 1px lightgray solid;
`;

export const GuestCardName = styled.div`
  //margin-right: 30px;
  width: 95%;

  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
  padding: 5px 8px;

  border-radius: 18px;

  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
`;

export const GuestCardNumber = styled.div`
  margin-bottom: 15px;
  //margin-right: 30px;
  width: 95%;

  color: #000;
  padding: 5px 8px;

  white-space: nowrap;
  overflow: hidden;
`;

export const footerCardContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: end;
  gap: 10px;
`;

export const ActionContainer = styled.div`
  display: flex;
  min-width: 50%;
  justify-content: start;
  gap: 10px;
`;

export const SearchInput = styled(Input.Search)`
  width: 45%;

  @media only screen and (max-width: 600px) {
    margin-top: 15px;
    width: 100%;
  }
`;

export const DataInfoContainer = styled.div`
  margin: 20px 0;

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  background-color: #5f02ad;

  color: #fff;

  padding: 5px;
  padding-left: 25px;

  border-radius: 20px;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    max-width: 100%;
    gap: 15px;

    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;

  }
`;
export const DataInfoFiltro = styled.div`
  width: 200px;
  min-width: 200px;
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    width: auto;
    min-width: auto;
  }

`;
export const DataInfoQtd = styled.div`
  width: 150px;
  min-width: 150px;

  @media only screen and (max-width: 600px) {
    width: auto;
    min-width: auto;
  }
`;

export const PiechartHolder = styled(PieChart)`
  background-color: #fff;
  display: flex;
  padding: 20px;
  width: 400px !important;
  height: 350px !important;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 18px;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ChartTitleContainer = styled.div`
  display: flex;
  justify-content: space-around;

  padding: 15px 0;
`;
export const ChartTitle = styled.div`
  font-family: "Roboto";
  font-size: large;
  text-transform: uppercase;
`;

export const ButtonGridContainer = styled.div`
  display: flex;
  //margin-bottom: 10px;
  gap: 15px;

  .action-buttons{
      display: flex;
      gap: 15px;
    }

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;

    .check-all{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .action-buttons{
      display: flex;
      width: 38%;
      justify-content: space-around;
      gap: 0;
    }

    .enviar-msg{
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  }
`;

export const ReloadButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;

  background-color: #69b1ff;
  color: #FFF;
  font-weight: 600;
   
   &&:hover{
    background-color: #91caff !important;
    color: #fff !important;
    //border-color: #FFF !important
   }
`

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding-top: 20px;
`

export {
  Slide,
  Container,
  ImageContainer,
  Image,
  Heart,
  HeadingContainer,
  EventInfos,
  ButtonScroll,
  // ButtonScrollIcon,
};
