// EtiquetaCredencial.js
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const Etiqueta = () => {
  const printData = useSelector((state) => state.print.printData);
  const printSize = useSelector((state) => state.print.printSize);

  return (
    <>
      {/* <button onClick={capturarComoImagem}>Capturar como Imagem</button> */}

      {/* <div style={{ height: "100vh", width: "100%", backgroundColor: "#FFF" }}> */}
      <EtiquetaContainer
        theme={{
          printSizeWidth: printSize.width,
          printSizeHeight: printSize.height,
          qrCode: printSize.qrCodeSize,
          qrCodeLocation: printSize.qrCodeLocation,
        }}
      >
        {printData.map((item, index) => (
          <EtiquetaWrapper
            className={`folha etiqueta-wrapper-${index}`}
            key={item.id}
            theme={{
              printSizeWidth: printSize.width * 1.4,
              printSizeHeight: printSize.height * 1.3,
              qrCode: printSize.qrCodeSize,
              qrCodeLocation: printSize.qrCodeLocation,
            }}
          >
            <DynamicSvgText
              text={item.name}
              fontSize={printSize.fontSize}
              textLocation={printSize.textLocation}
              theme={{
                printSizeWidth: printSize.width,
                printSizeHeight: printSize.height,
                qrCode: printSize.qrCodeSize,
                qrCodeLocation: printSize.qrCodeLocation,
              }}
            />
            <QRCodeContainer
            // className="folha"
              theme={{
                printSizeWidth: printSize.width,
                printSizeHeight: printSize.height,
                qrCode: printSize.qrCodeSize,
                qrCodeLocation: printSize.qrCodeLocation,
              }}
            >
              <QRCode
                src={item.qrcode}
                theme={{
                  printSizeWidth: printSize.width,
                  printSizeHeight: printSize.height,
                  qrCode: printSize.qrCodeSize,
                  qrCodeLocation: printSize.qrCodeLocation,
                }}
              />
            </QRCodeContainer>
          </EtiquetaWrapper>
        ))}
      </EtiquetaContainer>
      {/* </div> */}
    </>
  );
};

const DynamicSvgText = ({ text, fontSize, textLocation, theme }) => {
  const containerStyle = {
    display: "flex",
    width: `60%`,
    justifyContent: textLocation,
    textAlign: textLocation,
    paddingLeft:'1rem'
  };

  const textStyles = {
    fontSize: `${fontSize}px`,
    fontWeight: 600,
    textTransform: 'uppercase'
  };

  return (
    <div style={containerStyle}>
      <p style={textStyles}>{text}</p>
    </div>
  );
};

const EtiquetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  /* padding: 15px; */

  //gap: 15px;
  width: fit-content;
`;

const EtiquetaWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  min-width: ${(props) => props.theme.printSizeWidth + "mm"};
  min-height: ${(props) => props.theme.printSizeHeight + "mm"};

  max-width: ${(props) => props.theme.printSizeWidth + "mm"};
  max-height: ${(props) => props.theme.printSizeHeight + "mm"};

  border: 0.5px dashed #c1c1c1;
  border-radius: 10px;

  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-left: 1rem;
`;

const QRCodeContainer = styled.div`
  display: flex;
  position: relative;
  width: 40%;
  max-width: 50%;

  max-height: 100%;

  img {
    object-fit: contain;
  }

  top: ${(props) => props.theme.qrCodeLocation.up}px;
  left: ${(props) => props.theme.qrCodeLocation.left}px;
`;

const QRCode = styled.img`
  height: auto;
  width: auto;
  max-height: ${(props) => props.theme.qrCode}%;
  max-width: ${(props) => props.theme.qrCode}%;
`;

export default Etiqueta;
