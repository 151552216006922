import React, { useRef } from "react";
import * as S from "../../styles";
import { useEffect } from "react";
import { hostsListByEvent } from "../../../../services/hosts";
import { useState } from "react";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import GuestCard from "./GuestCard";
import { Checkbox, Spin } from "antd";
import OptionsGrid from "./OptionsGrid";
import {
  onChangeFiltro,
  orderList,
  searchGuest,
  criarArquivoZip,
} from "./functions";
import {
  setPrintData,
  setPrintSize,
} from "../../../../store/modules/print/actions";
import { useDispatch } from "react-redux";
import ModalEtiqueta from "../Eiqueta/ModalEtiqueta";
import { useSelector } from "react-redux";
import ModalCarregamento from "./components/ModalCarregamento";

const GuestGrid = ({ event }) => {
  const CheckboxGroup = Checkbox.Group;
  const dispatch = useDispatch();

  const [guest, setGuests] = useState();
  const [allGuest, setAllGuests] = useState();
  const [checked, setChecked] = useState([]);
  const [value, setValue] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibilityPrint, setVisibilityPrint] = useState(false);
  const [credential, setCredential] = useState([]);
  const [imageInvites, setImageInvites] = useState();
  const [carregandoImagens, setCarregandoImagens] = useState(false);
  const [imageIncrement, setImageIncrement] = useState(0);
  const [invite, setInvite] = useState();
  const stopLoopRef = useRef(false);

  const { user } = useSelector((state) => state.user);

  const [showButton, setShowButton] = useState(false);

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setChecked(guest.map((objeto) => objeto.id));
      setCheckAll(e.target.checked);
    } else {
      setChecked([]);
      setCheckAll(false);
      setIndeterminate(false);
    }
  };

  const getGuests = async (id) => {

    if(!id){
      id = event.id;
    }

    setLoading(true);

    const data = await hostsListByEvent(id);
    setGuests(data?.result?.list);
    setAllGuests(data?.result?.list);

    setLoading(false);
  };

  const onChangeCheck = (value) => {
    if (value.target.checked === true) {
      setChecked([...checked, value.target.value]);
    } else {
      let data = checked.filter((x) => x !== value.target.value);
      setChecked(data);
    }
  };

  const setAllGuest = () => {
    setGuests(allGuest);
  };

  useEffect(() => {
    getGuests(event.id);
  }, [event]);

  useEffect(() => {
    if (user) {
      switch (user.user.type) {
        case "hosts":
          setShowButton(false);
          break;
        case "adm":
          setShowButton(true);
          break;
        default:
          setShowButton(false);
          break;
      }
    }
  }, [user]);

  useEffect(() => {
    let qtdCheck = checked.length;
    let qtdAtual = guest?.length;
    if (checkAll) {
      setIndeterminate(qtdCheck !== qtdAtual);
    }
  }, [checked, guest, checkAll]);

  const changeFilter = (value) => {
    let valor = value?.target?.value ?? value;
    onChangeFiltro({ valor, setGuests, allGuest, setValue, guest });
  };

  const changeOrder = (e) => {
    orderList({ e, guest, setGuests });
  };

  const changeSearch = (e) => {
    searchGuest({ e, value, allGuest, setGuests, guest, changeFilter });
  };

  const print = (data) => {
    setVisibilityPrint(true);
    setCredential(data);
  };

  const printSize = (data) => {
    constructPrint(credential, data);
    setVisibilityPrint(false);
  };

  const baixarConvites = () => {
    stopLoopRef.current = false;

    let invites =
      checked.length > 0
        ? allGuest.filter((x) => checked.includes(x.id))
        : guest;
    setInvite(invites);
    criarArquivoZip(
      invites,
      event,
      setCarregandoImagens,
      setImageIncrement,
      setImageInvites,
      stopLoopRef
    );
  };

  const parar = () => {
    setCarregandoImagens(false);
    stopLoopRef.current = true;
  };

  const constructPrint = (data, size) => {
    if (data) {
      let cred = allGuest.filter((x) => data.includes(x.id));
      dispatch(setPrintData(cred));
      dispatch(setPrintSize(size));
      window.open("/print", "_blank");
      //gerarPlanilha(cred);
    } else {
      let cred =
        checked.length > 0
          ? allGuest.filter((x) => checked.includes(x.id))
          : guest;
      dispatch(setPrintData(cred));
      dispatch(setPrintSize(size));
      window.open("/print", "_blank");
    }
  };

  return guest !== undefined ? (
    <S.GuestContainer>
      <OptionsGrid
        indeterminate={indeterminate}
        onCheckAllChange={onCheckAllChange}
        checkAll={checkAll}
        orderList={changeOrder}
        onChangeFiltro={changeFilter}
        value={value}
        searchGuest={changeSearch}
        guest={guest}
        allGuest={allGuest}
        checked={checked}
        setAllGuests={setAllGuest}
        event={event}
        getGuests={getGuests}
        print={print}
        baixarConvites={baixarConvites}
      />
      {!loading ? (
        <CheckboxGroup value={checked}>
          <S.CheckContainer>
            {guest?.map((item, index) => (
              <GuestCard
                key={index}
                guest={item}
                onChangeCheck={onChangeCheck}
                print={print}
                showButton={showButton}
              />
            ))}
          </S.CheckContainer>
        </CheckboxGroup>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "80px",
          }}
        >
          <RotatingLines
            strokeColor="rgb(82, 9, 168)"
            color="rgb(82, 9, 168)"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      <ModalEtiqueta
        visibilityPrint={visibilityPrint}
        setVisibilityPrint={setVisibilityPrint}
        printSize={printSize}
      />
      <ModalCarregamento
        visibility={carregandoImagens}
        setVisibility={parar}
        checked={invite}
        guest={imageInvites}
        increment={imageIncrement}
      />
    </S.GuestContainer>
  ) : (
    <div
      style={{
        height: "50%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="rgb(82, 9, 168)"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
};

export default GuestGrid;
