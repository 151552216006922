import axios from 'axios';

let baseURL = 'https://api.puzli.com.br';

export const api = axios.create({
    baseURL,
});

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response?.status === 401) {
            try {
                const refresh = JSON.parse(localStorage.getItem('PuzliAppRefreshToken') || '');
                const type = JSON.parse(localStorage.getItem('PuzliAppType') || '');
                const session = JSON.parse(localStorage.getItem('PuzliAppSession') || '');

                const refreshResponse = await axios.post(`${baseURL}/clients/refreshToken`, {
                    refresh,
                    type,
                    session,
                });

                localStorage.setItem('PuzliAppClient', JSON.stringify(refreshResponse.data.result.client));
                localStorage.setItem('PuzliAppType', JSON.stringify(type));
                localStorage.setItem('PuzliAppToken', JSON.stringify(refreshResponse.data.result.token));
                localStorage.setItem('PuzliAppRefreshToken', JSON.stringify(refreshResponse.data.result.refreshToken));
                localStorage.setItem('PuzliAppSession', JSON.stringify(refreshResponse.data.result.client.session));

                api.defaults.headers['x-access-token'] = refreshResponse.data.result.token;
            } catch (refreshError) {
                // Implement error handling as needed
                console.error(refreshError);
            }
        } else {
            return Promise.reject(error.response);
        }

        return Promise.reject(error);
    },
);
