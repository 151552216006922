import JSZip from 'jszip';

export const criarArquivoZip = async (convitesSelecionados, event, setCarregandoImagens, setImageIncrement, setImageInvites, stopLoopRef) => {
  const zip = new JSZip();
  const requests = [];

  setCarregandoImagens(true);

  // Adicione cada convite ao arquivo zip
  for (const [index, convite]  of convitesSelecionados.entries()) {
    try {
      if(stopLoopRef.current){
        break;
      }
      const response = await fetch(convite?.invitation);
      const blob = await response.blob();
      zip.file(`${convite?.name?.trim()}.${blob?.type?.split('/')[1]}`, blob);
      setImageIncrement(index+1);
      setImageInvites(convite);
      requests.push(response);
    } catch (error) {
      console.error('Erro ao obter convite:', error);
    }
  }

  // Aguarde todas as solicitações serem concluídas
  await Promise.all(requests);

  const zipData = await zip.generateAsync({
    type: "blob",
    streamFiles: true,
  });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(zipData);
  link.download = `${event.name}`;
  link.click();

  setCarregandoImagens(false);
};