import { Modal } from 'antd'
import React from 'react'
import Piechart from './Piechart'

const ModalChart = ({visible, setVisible, allGuest}) => {
  return (
    <Modal
    open={visible}
    onCancel={() => {setVisible(false)}}
    destroyOnClose
    width={"100%"}
    min-height={"600px"}
    >
        <Piechart allGuest={allGuest}/>
    </Modal>
  )
}

export default ModalChart