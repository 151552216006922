import React, { useCallback, useEffect } from "react";
import {
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";
import { dashboardEvent } from "../../../../services/events";
import { useParams } from "react-router-dom";
import { useState } from "react";
import * as S from "../../styles";

const Piechart = ({ event, allGuest }) => {
  const [dados, setDados] = useState();
  let params = useParams();

  const generatePieChartData = (chartType, data) => {
    if (data) {
      if (chartType === "presence") {
        const presentes = data.guests.on.qtd;
        const faltantes = data.guests.off.qtd;

        return [
          { name: `Presentes (${presentes})`, value: presentes },
          { name: `Faltantes (${faltantes})`, value: faltantes },
        ];
      } else if (chartType === "scanning") {
        const corretos = data.scans?.correct;
        const incorretos = data.scans?.incorrect;

        return [
          { name: `Corretos (${corretos})`, value: corretos},
          { name: `Incorretos (${incorretos})`, value: incorretos},
          { name: `Não Lidos (${data.guests.all})`, value: data.guests.all},
        ];
      } else if (chartType === "status") {
        // const total = data.guests.all;
        const aprovados = allGuest.filter(
          (x) => x.status === "approved"
        ).length;
        const recusados = allGuest.filter(
          (x) => x.status === "disapproved"
        ).length;
        const pendentes = allGuest.filter((x) => x.status === "pending").length;

        return [
          { name: `Aprovados (${aprovados})`, value: aprovados },
          { name: `Recusados (${recusados})`, value: recusados },
          { name: `Pendentes (${pendentes})`, value: pendentes },
        ];
      } else if (chartType === "confirm") {
        const confirmaram = allGuest.filter(
          (x) => x.confirm_presence === true
        ).length;
        const naoConfirmaram = allGuest.filter(
          (x) => x.confirm_presence === false
        ).length;

        return [
          { name: `Confirmaram (${confirmaram})`, value: confirmaram },
          { name: `Não Confirmaram (${naoConfirmaram})`, value: naoConfirmaram },
        ];
      } else {
        console.error("Tipo de gráfico não suportado:", chartType);
        return [];
      }
    }
  };


  const cellsStatus = (data) => {
    if (data) {
      const total = data.guests.all;
      return (
        <>
          <Label
            content={"Total"}
            value={total}
            color="#FFF"
            position={"center"}
            fontSize={250}
          />
          <Cell fill={`#2bd463`} />
          <Cell fill={`#D20405`} />
          <Cell fill={`#01b0c7`} />
        </>
      );
    }
  };

  const cellConfirmacao = (data) => {
    if (data) {
      const total = data.guests.all;

      return (
        <>
          <Label
            content={"Total"}
            value={total}
            color="#FFF"
            position={"center"}
            fontSize={250}
          />
          <Cell fill={`#2bd463`} />
          <Cell fill={`#D20405`} />
        </>
      );
    }
  };

  const cellPresenca = (data) => {
    if (data) {
      const total = data.guests.all;
      return (
        <>
          <Label
            content={"Total"}
            value={total}
            color="#FFF"
            position={"center"}
            fontSize={250}
          />
          <Cell fill={`#2bd463`} />
          <Cell fill={`#D20405`} />
        </>
      );
    }
  };

  const cellScan = (data) => {
    if (data) {
      const total = data.guests.all;
      return (
        <>
          <Label
            content={"Total"}
            value={total}
            color="#FFF"
            position={"center"}
            fontSize={250}
          />
          <Cell fill={`#2bd463`} />
          <Cell fill={`#D20405`} />
          <Cell fill={`#01b0c7`} />
        </>
      );
    }
  };

  const getDashboardInfo = useCallback(async () => {
    const data = await dashboardEvent(params?.id);
    return data;
  }, [params?.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDashboardInfo();
        setDados(data.result);
      } catch (error) {}
    };

    fetchData();
  }, [getDashboardInfo]);

  return (
    <S.ChartContainer>
    <S.ChartTitleContainer>
    <S.ChartTitle>Presença</S.ChartTitle>
    <S.ChartTitle>Quantidades</S.ChartTitle>
    <S.ChartTitle>Leituras</S.ChartTitle>
    <S.ChartTitle>Confirmação</S.ChartTitle>
    </S.ChartTitleContainer>
    <ResponsiveContainer
      width="100%"
      height={400}
      style={{ display: "flex", gap: "25px" }}
    >
      <S.PiechartHolder>
        <Legend layout="vertical" verticalAlign="top" align="top" />
        <Pie
          data={generatePieChartData("presence", dados)}
          cx="50%"
          cy="75%"
          innerRadius="272%"
          outerRadius="332%"
          paddingAngle={0}
          startAngle={-90}
          dataKey="value"
        >
          {cellPresenca(dados)}
        </Pie>
      </S.PiechartHolder>

      <S.PiechartHolder>
        <Legend layout="vertical" verticalAlign="top" align="top" />
        <Pie
          data={generatePieChartData("status", dados)}
          cx="50%"
          cy="75%"
          innerRadius="290%"
          outerRadius="350%"
          paddingAngle={0}
          startAngle={-90}
          dataKey="value"
        >
          {cellsStatus(dados)}
        </Pie>
      </S.PiechartHolder>

      <S.PiechartHolder>
        <Legend layout="vertical" verticalAlign="top" align="top" />
        <Pie
          data={generatePieChartData("scanning", dados)}
          cx="50%"
          cy="75%"
          innerRadius="290%"
          outerRadius="350%"
          paddingAngle={0}
          startAngle={-90}
          dataKey="value"
        >
          {cellScan(dados)}
        </Pie>
      </S.PiechartHolder>

      <S.PiechartHolder>
        <Legend layout="vertical" verticalAlign="top" align="top" />
        <Pie
          data={generatePieChartData("confirm", dados)}
          cx="50%"
          cy="70%"
          innerRadius="350%"
          outerRadius="420%"
          paddingAngle={0}
          startAngle={-90}
          dataKey="value"
        >
          {cellConfirmacao(dados)}
        </Pie>
      </S.PiechartHolder>

    </ResponsiveContainer>
    </S.ChartContainer>
  );
};

export default Piechart;
