import React from 'react';
// import { DesktopView } from './views/DesktopView';
import Login from './views/Login'
import * as S from './styles';

export function SignInScreen({ navigation }) {
    return (
        <S.Container>
            <Login />
        </S.Container>
    );
}
