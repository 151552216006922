import user from './user';
import print from './print'

export const actions = {
    user: user.actions,
    print: print.actions
};

export const reducers = {
    user: user.reducer,
    print: print.printReducer
};
