import React from 'react'
import EventsScreen from '../Events'

const Pages = () => {
  return (
    <>
    <EventsScreen/>
    </>
  )
}

export default Pages