import React from 'react';
import * as S from './styles';

export function Heading({
    children,
    color = 'black',
    size = 'medium',
    marginBottom = 0,
    marginTop = 0,
    marginRight = 0,
}) {
    return (
        <S.Wrapper
            marginRight={marginRight}
            color={color}
            size={size}
            marginBottom={marginBottom}
            marginTop={marginTop}
        >
            {children}
        </S.Wrapper>
    );
}
