import React, { useState, useEffect } from "react";
import * as S from "../../../styles";
import { Button, Checkbox, Divider, Spin, Tooltip } from "antd";
import Swal from "sweetalert2";
import ModalMensagem from "./ModalMensagem";
import ModalCarregamento from "./ModalCarregamento";
import { Reload } from "styled-icons/ionicons-outline";
import {
  FileExcelOutlined,
  PrinterOutlined,
  DownloadOutlined,
  UserSwitchOutlined,
  FileDoneOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { gerarRelatorio } from "../functions/RelatorioFunction";
import { useSelector } from "react-redux";
import { sendList, verificaNumeros, verificarNumerosBatch } from "../functions/SendListFunction";
import ModalChangeStatus from "./ChangeStatus/ModalChangeStatus";
import { InfinitySpin } from "react-loader-spinner";


const ButtonGrid = ({
  checked,
  event,
  allGuest,
  getGuests,
  indeterminate,
  onCheckAllChange,
  checkAll,
  print,
  baixarConvites
}) => {
  const [message, setMessage] = useState();
  const [messageEnvio, setMessageEnvio] = useState();
  const [guest, setGuest] = useState();
  const [visible, setVisible] = useState();
  const [visibility, setVisibility] = useState();
  const [loading, setLoading] = useState(false);
  const [increment, setIncrement] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [showButtonHostess, setShowButtonHostess] = useState(false);
  const [changeStatusVisibility, setChangeStatusVisibility] = useState(false);
  const [loadState, setLoadState] = useState(false);

  const { user } = useSelector((state) => state.user);

  const enviarMensagemConfirmacao = () => {
    //const data = checked;
    //const evento = event;
    if (checked.length === 0) {
      Swal.fire({
        title: "Oops",
        text: "Nenhum convidado selecionado.",
        icon: "warning",
        timer: 2000,
        showConfirmButton: false,
      });
    } else {
      setVisible(true);
    }
  };

  const handleOk = () => {
    setIncrement(0);
    setLoading(false);
    setVisibility(false);
    setVisible(false);
    setMessage();
    setGuest();
  };

  const reloadGuests = () => {
    getGuests(event?.id);
  };

  const sendListMessage = async () => {
    await sendList(
      event,
      checked,
      allGuest,
      message,
      setMessageEnvio,
      setGuest,
      setIncrement,
      setLoading,
      setVisibility
    );
  };


  const verificarListaNumeros = async () => {
    await verificarNumerosBatch(event,allGuest, setGuest, setVisibility, setIncrement, setLoadState, setTotalCount);
  };


  const changeStatus = () => {
    if (checked.length > 0) {
      setChangeStatusVisibility(true);
    } else {
      Swal.fire({
        title: "Oops",
        text: "Nenhum convidado selecionado.",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    if (user) {
      switch (user.user.type) {
        case "hosts":
          setShowButton(false);
          break;
        case "adm":
          if (user.user.permission === "master") {
            setShowButton(true);
          } else {
            setShowButtonHostess(true);
          }
          break;
        default:
          setShowButton(false);
          break;
      }
    }
  }, [user]);

  return (
    <S.ButtonGridContainer>
      <Spin indicator={<InfinitySpin style={{ fontSize: 24 }} color="#4c018a" />} spinning={loadState} fullscreen />
      {showButton && (
        <div className="check-all">
          <Divider
            type="vertical"
            style={{ backgroundColor: "#000", height: "2em" }}
          />

          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Selecionar todos
          </Checkbox>

          <Divider
            type="vertical"
            style={{ backgroundColor: "#000", height: "2em" }}
          />
        </div>
      )}

      <div className="action-buttons">
        <Tooltip title="Atualizar convidados">
          <S.ReloadButton type="primary" shape="circle" onClick={reloadGuests}>
            <Reload width={20} />
          </S.ReloadButton>
        </Tooltip>

        {!showButtonHostess && (
          <Tooltip title="Gerar relatório">
            <Button
              shape="circle"
              onClick={() => gerarRelatorio(allGuest, event)}
            >
              <FileExcelOutlined />
            </Button>
          </Tooltip>
        )}

        {/* <Tooltip title="Gerar relatório completo">
          <Button
            shape="circle"
            onClick={() => gerarRelatorio(allGuest, event)}
          >
            <FileDoneOutlined />
          </Button>
        </Tooltip> */}

        {showButton ||
          (showButtonHostess && (
            <Tooltip title="Gerar etiqueta">
              <Button shape="circle" onClick={() => print()}>
                <PrinterOutlined />
              </Button>
            </Tooltip>
          ))}

        {showButton && (
          <Tooltip title="Baixar Convites">
            <Button shape="circle" onClick={() => baixarConvites()}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        )}

        {showButton && (
          <Tooltip title="Alterar Status de Convidados">
            <Button shape="circle" onClick={() => changeStatus()}>
              <UserSwitchOutlined />
            </Button>
          </Tooltip>
        )}

        {showButton && (
          <Tooltip title="VerificarNumeros">
            <Button shape="circle" onClick={() => verificarListaNumeros()}>
            <PhoneOutlined />
            </Button>
          </Tooltip>
        )}
      </div>

      {showButton && (
        <div className="enviar-msg">
          <Button type="primary" onClick={enviarMensagemConfirmacao}>
            Enviar Mensagem de Confirmação
          </Button>
        </div>
      )}

      <ModalMensagem
        visible={visible}
        setVisible={setVisible}
        setMessage={setMessage}
        handleOk={handleOk}
        message={message}
        loading={loading}
        sendList={sendListMessage}
      />
      <ModalCarregamento
        increment={increment}
        visibility={visibility}
        setVisibility={setVisibility}
        checked={checked}
        guest={guest}
        handleOk={handleOk}
        messageEnvio={messageEnvio}
        loading={loading}
        totalCount={totalCount}
      />
      <ModalChangeStatus
        changeStatusVisibility={changeStatusVisibility}
        setChangeStatusVisibility={setChangeStatusVisibility}
        checked={checked}
        allGuest={allGuest}
        getGuests={getGuests}
      />
    </S.ButtonGridContainer>
  );
};

export default ButtonGrid;
