import React from 'react'
import * as S from '../../../styles'
import ModalChart from '../../Charts/ModalChart';
import { useState } from 'react';
import { Tooltip } from 'antd';
const DataInfoComp = ({filtro, guest, allGuest, checkAll, checked}) => {
  const [visible, setVisible] = useState(false);

  const filtros = (v) => {
    switch (v) {
      case 1:
        return "Pendente";
      case 2:
        return "Aprovado";
      case 3:
        return "Recusado";
      case 4:
        return "Presença confirmada";
        case 5:
          return "Presença negada/não respondeu.";
      case 999:
        return "Com telefone";
      default:
        return;

    }
  }
  return (
    <>
    <Tooltip title="Clique para ver detalhes">
    <S.DataInfoContainer onClick={() => setVisible(true)}>
      <S.DataInfoFiltro>Filtro: {filtro === 0 ? 'Nenhum' : filtros(filtro)}</S.DataInfoFiltro>
      <S.DataInfoQtd>Quantidade: {guest?.length}</S.DataInfoQtd>
      <S.DataInfoQtd>Selecionados: {checked?.length}</S.DataInfoQtd>
    </S.DataInfoContainer>
    </Tooltip>
    <ModalChart visible={visible} setVisible={setVisible} allGuest={allGuest}/>
    
    </>
  )
}

export default DataInfoComp