const primary = '#5f02ad'
export const theme = {
    token: {
    colorPrimary: primary,

    contentBg: '#F0F',

    Button: {
      colorPrimary: primary,
      algorithm: true, 
      borderRadius: '5px',
    },

    Progress:{
      colorInfo: primary
    }
    }
  }