import { api } from './api';
import { getToken } from './functions/getToken';
import { ErrorAlert } from '../components/ErrorAlert';

export async function listCategories() {
    try {
        const token = await getToken();
        const { data } = await api.get('/categories', {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch {
        throw new Error();
    }
}

export async function registerGuest(guests, hash) {
    try {
        const token = await getToken();
        const { data } = await api.post('/guests', {
            guests,
            idEvent: hash,
        }, {
            headers: {
                'x-access-token': token,
            },
        });
        return data;
    } catch {
        throw new Error();
    }
}

export async function dashboardEvent(idEvent) {
    try {
        const token = await getToken();
        const { data } = await api.get(`/events/dashboard?idEvent=${idEvent}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) { return error; }
}

export async function getAllEvents(id, type, idsCategory="", period="", searchTxt="") {
    const url = `/events?idReference=${id}&type=${type}${idsCategory ? `&idsCategory=${idsCategory}` : ''}${period ? `&period=${period}` : ''}`;
    try {
        const token = await getToken();
        const { data } = await api.get(url, {
            headers: {
                'x-access-token': token,
            },
            params: {
                searchTxt,
            },
        });

        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function getEvent(hash) {
    try {
        const token = await getToken();
        const { data } = await api.get(`/events/uniqueEvent?hash=${hash}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function eventBlocked(idEvent, isBlocked) {
    const token = await getToken();
    try {
        const { data } = await api.put('events/blocked', {}, {
            headers: {
                'x-access-token': token,
            },
            params: {
                idEvent,
                isBlocked,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}
