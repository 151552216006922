// routes/index.jsx
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet} from "react-router-dom";
import { SignInScreen } from "../screens/SignInScreen";
import { Home } from "../screens/Home";
import { UserProvider, useUserContext } from "../context/userContext";
import EventDetail from "../screens/Events/EventDetail";
import Navigation from "../components/Navigation";
import Etiqueta from "../screens/Events/components/Eiqueta/Etiqueta";

const MainRoutes = () => {
  
  const [credencial, setCredencial] = useState([]);

  return (
    <UserProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/event/:id" element={<EventDetail credencial={credencial} setCredencial={setCredencial} />} />
            <Route path="/print" element={<Etiqueta credencial={credencial} />} />
          </Route>
          <Route path="/login" element={<SignInScreen />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

const ProtectedRoute = () => {
  const { user } = useUserContext();
  const isLoggedIn = Object.keys(user).length > 0;
  const local = JSON.stringify(localStorage).includes("@PuzliApp:token");

  if (!isLoggedIn && !local) {
    return <Navigate to="/login" replace={true}/>;
  }

  return <Outlet />;
};


export default MainRoutes;
