import * as XLSX from "xlsx";
import {
  enviarLista,
  listaNumeros,
  verificarNumeroLote,
} from "../../../../../services/zapi";
import Swal from "sweetalert2";
import { forEach } from "jszip";

function destacarTextoComBold(texto) {
  return texto.replace(/\*(.*?)\*/g, "<b>$1</b>");
}

export const sendList = async (
  event,
  checked,
  allGuest,
  message,
  setMessageEnvio,
  setGuest,
  setIncrement,
  setLoading,
  setVisibility
) => {
  if (message) {
    setLoading(true);
    setVisibility(true);

    const objetosEnviados = [];

    for (let i = 0; i < checked?.length; i++) {
      const guestId = checked[i];
      const guestObj = allGuest.find((x) => x.id === guestId);
      let msgEnvio;

      var msg = destacarTextoComBold(message?.replace("_name_", guestObj.name));

      msgEnvio = message?.replace("_name_", guestObj.name);

      setGuest(guestObj);
      setMessageEnvio(msg);

      await new Promise((resolve) => {
        enviarLista(event, msgEnvio, guestObj)
          .then((result) => {
            setIncrement(i + 1);

            objetosEnviados.push({
              name: guestObj.name,
              mensagem: msgEnvio,
              telefone: guestObj.telephone,
              status: result.status === 200 ? "SUCESSO" : "ERRO",
              erro: result?.error,
            });
            resolve();
          })
          .catch((error) => {
            objetosEnviados.push({
              name: guestObj.name,
              mensagem: msgEnvio,
              telefone: guestObj.telephone,
              status: error?.status === 200 ? "SUCESSO" : "ERRO",
              erro: error?.message,
            });
            resolve();
          });
      });
    }

    var data = new Date();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(objetosEnviados);
    XLSX.utils.book_append_sheet(wb, ws, "ObjetosEnviados");
    XLSX.writeFile(wb, `objetos_enviados_${data}.xlsx`);

    return true;
  } else {
    Swal.fire({
      title: "Oops",
      text: "Campo mensagem não pode estar em branco.",
      icon: "error",
    });
    return false;
  }
};

export const verificarNumerosBatch = async (
  event,
  allGuest,
  setGuest,
  setVisibility,
  setIncrement,
  setLoadState,
  setTotalCount
) => {
  const objetosEnviados = [];
  let phoneNull = [];

  setLoadState(true);

  const phones = allGuest.map((item) => {
    let telefone = removerCaracteresEspeciais(`55${item.telephone}`);
    if (item.telephone?.length > 0 && item.telephone !== null && item.telephone !== "") {
        return telefone;
    } else {
        phoneNull.push({
            Nome: item.name,
            Telefone: item.telephone,
            Retorno: "TELEFONE NÃO INFORMADO"
        });
        return null;
    }
}).filter(phone => phone !== null && phone !== undefined);

  const phonesValidos = phones.filter(phone => formatarTelefone(phone).length >= 10);

  const phonesInvalidos = phones.filter(phone => (formatarTelefone(phone).length < 10) && (formatarTelefone(phone).length > 0)).map(phone => ({
    Nome: allGuest.find(x => x.telephone == formatarTelefone(phone))?.name,
    Telefone: formatarTelefone(phone),
    Retorno: "DDD NÃO INFORMADO OU NUMERO INCOMPLETO"
  }));

  await new Promise((resolve) => {
    verificarNumeroLote(event, phonesValidos)
    .then((result) => {
      for (var i = 0; i < result.length; i++) {
        objetosEnviados.push({
          telephone: result[i].inputPhone,
          retorno: result[i]?.exists,
        });
      }
      resolve();
    });
  });

  let telefonesIguaisObj = allGuest
    .filter((guest) => {
      return objetosEnviados.some((obj) => telefonesIguais(guest, obj));
    })
    .map((guest) => {
      let telefoneFormatado = remover9(removerCaracteresEspeciais(guest.telephone));
      let objetoEnviado = objetosEnviados.find(obj => remover9(formatarTelefone(obj.telephone)) === telefoneFormatado);
      
      return {
        ...guest,
        exists: objetoEnviado ? objetoEnviado.retorno : false,
      };
    });

  var objLista = telefonesIguaisObj.map((item)=>{
    return {
      Nome: item.name,
      Telefone: item.telephone,
      Retorno: item.exists ? 'VÁLIDO' : 'INVÁLIDO OU INTERNACIONAL'
    }
  });

  objLista = objLista.concat(phonesInvalidos);
  objLista = objLista.concat(phoneNull);

  var nomeArquivo = `Numeros_Validos_${formatarDataArquivo()}.xlsx`;
  
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(objLista);
  XLSX.utils.book_append_sheet(wb, ws, "Telefones");
  XLSX.writeFile(wb, nomeArquivo);
  
  
  setLoadState(false);
};

function formatarDataArquivo(data){
  var data = new Date().toISOString();
  return data?.replaceAll("-","_").replaceAll(":","").split('.')[0];
}

function formatarTelefone(telefone) {
  return telefone?.replace(/\D/g, "").substring(2);
}

function removerCaracteresEspeciais(str) {
  return str?.replace(/[^0-9]/g, "");
}

function remover9(str){
  if(str?.length == 11){
  return str?.slice(0, 2) + str?.slice(3);
  }else{
    return str;
  }
}

function telefonesIguais(objeto1, objeto2) {
  let telefone1 = removerCaracteresEspeciais(objeto1.telephone);
  let telefone2 = formatarTelefone(objeto2.telephone);
  if(telefone1 === telefone2){
    return true;
  }else if((telefone1?.slice(0, 2) + telefone1?.slice(3)) == telefone2){
    return true;
  }else{
    return false;
  };
}


export const verificaNumeros = async (
  event,
  allGuest,
  setGuest,
  setVisibility,
  setIncrement,
  setLoading,
  setTotalCount
) => {
  const objetosEnviados = [];

  setLoading();
  setVisibility(true);
  setTotalCount(allGuest?.length);

  for (let i = 0; i < allGuest?.length; i++) {
    const guestObj = allGuest[i];

    setGuest(guestObj);

    setIncrement(i + 1);
    await new Promise((resolve) => {
      listaNumeros(event, guestObj.telephone)
        .then((result) => {
          objetosEnviados.push({
            name: guestObj.name,
            telefone: guestObj.telephone,
            retorno: result?.retorno,
          });
          resolve();
        })
        .catch((error) => {
          objetosEnviados.push({
            name: guestObj.name,
            telefone: guestObj.telephone,
            status: error?.status === 200 ? "SUCESSO" : "ERRO",
            retorno: error?.message,
          });
          resolve();
        });
    });
  }

  var data = new Date();
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(objetosEnviados);
  XLSX.utils.book_append_sheet(wb, ws, "ObjetosEnviados");
  XLSX.writeFile(wb, `Numeros_Validos${data.getTime()}.xlsx`);

  return true;
};
