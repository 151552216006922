import React from "react";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import {HeartFilled, HeartOutlined} from '@ant-design/icons'
import { useSelector } from "react-redux";
import { Heading } from "../../../components/Heading";
import * as S from "../styles";
import { defaultTheme as theme } from "../../../styles/theme";
import { useNavigate } from 'react-router-dom';

export function Event({ event, navigation, allEventsList, isFavoriteInCategory }) {
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user.user);
  // const [loadingImg, setLoadingImg] = useState(false);
  const coverImage = event.front_cover;
  const existsEventInFavorites = isFavoriteInCategory(event);

  const handleClickEvent = (id, event) => {
    navigate(`/event/${id}`, {state: {allEventsList: allEventsList}})
  };

  const onLoadImage = () => {
    // setLoadingImg(true);
  };

  const date = new Date(event.date);

  // const favoriteEvent = () => {
  //   onFavorite(event);
  // };

  const renderFavoritesButton = () => {
    if (user?.user.permission === "receptionist") {
      return null;
    }

    return (
      <S.Heart>
        {/* <Entypo
                    name={existsEventInFavorites ? 'heart' : 'heart-outlined'}
                    size={20}
                    color={theme.colors.purple}
                /> */}
        {existsEventInFavorites ? <HeartFilled  style={{color:theme.colors.purple}} /> : <HeartOutlined style={{color:theme.colors.purple}} />}
      </S.Heart>
    );
  };

  return (
    <S.Slide
      blocked={event.blocked_at}
      key={event.id}
      onClick={() => handleClickEvent(event.id, event)}
      style={{cursor: 'pointer'}} 
    >
      <S.ImageContainer>
        <S.Image onLoad={onLoadImage} src={coverImage} />
        {renderFavoritesButton()}
      </S.ImageContainer>
      <S.HeadingContainer>
        <Heading color="gray" size="xsmall" marginTop={12}>
          {event?.name}
        </Heading>
      </S.HeadingContainer>
      <S.EventInfos>
        {format(
          date.setDate(date.getDate() + 1),
          "EEEE, dd 'de' MMMM 'de' yyyy",
          { locale: ptBR }
        )}
      </S.EventInfos>
    </S.Slide>
  );
}
