import { blue } from "@ant-design/colors";
import { Button, Dropdown, Tooltip } from "antd";
import { ArrowDownUp } from "@styled-icons/bootstrap/ArrowDownUp";
import React from "react";
import styled from 'styled-components';
const OrderComp = ({ orderList }) => {
  const onClick = ({ key }) => {
    orderList(key);
  };

  const CustomButton = styled(Button)`
    background-color: #ff85c0;

    &&:hover {
      background-color: #ffadd2 !important;
    }
`;

  const items = [
    {
      label: "Ordem Alfabética (A-Z)",
      key: "1",
    },
    {
      label: "Ordem Alfabética (Z-A)",
      key: "2",
    },
    {
      label: "Data de cadastro (asc)",
      key: "3",
    },
    {
      label: "Data de cadastro (desc)",
      key: "4",
    },
  ];

  return (
    <Tooltip title="Ordenação da lista de convidados">
      <Dropdown
        menu={{
          items,
          onClick,
        }}
      >
        <CustomButton type="primary" shape="circle">
          <ArrowDownUp className="order-menu" size={20} />
          </CustomButton>
      </Dropdown>
    </Tooltip>
  );
};

export default OrderComp;
