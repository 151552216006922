import { api } from './api';
import { getToken } from './functions';
import { ErrorAlert } from '../components/ErrorAlert';

export async function hostsList(idAdm, idEvent) {
    const token = await getToken();
    try {
        const { data } = await api.get(idEvent ? `hosts?idAdm=${idAdm}&idEvent${idEvent}` : `hosts?idAdm=${idAdm}`, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function hostsListNoAdm() {
    const token = await getToken();
    try {
        const { data } = await api.get('hosts', {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function hostBlocked(idHost, isBlocked) {
    const token = await getToken();
    try {
        const { data } = await api.put(`hosts/blocked?idHost=${idHost}&isBlocked=${isBlocked}`, {}, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function hostsFavorite(idHost, isFavorite, idAdmin) {
    const token = await getToken();
    try {
        const data = await api.post('/favorite/hosts', {
            idAdm: idAdmin,
            idHost,
            isFavorite,
        }, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function hostDelete(idHost) {
    try {
        const token = await getToken();
        const response = await api.delete('/hosts', {
            headers: {
                'x-access-token': token,
            },
            params: {
                idHost,
            },
        });
        if (response.data.status !== 200) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response;
    } catch (error) {
        return error;
    }
}

export async function hostUpdate(idHost, host) {
    const token = await getToken();
    try {
        const { data } = await api.put(`/hosts?idHost=${idHost}`, host, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}

export async function hostCreate(host) {
    const token = await getToken();
    try {
        const response = await api.post('/hosts', host, {
            headers: {
                'x-access-token': token,
            },
        });
        if (response.data.status !== 201) {
            ErrorAlert('Algo deu errado!', response.data.message);
        }
        return response;
    } catch (error) {
        return error;
    }
}

export async function hostsListByEvent(idEvent, searchTxt, status) {
    const url = `/guests?idEvent=${idEvent}&searchTxt=&status=`;

    const token = await getToken();
    try {
        const { data } = await api.get(url, {
            headers: {
                'x-access-token': token,
            },
        });
        if (data.status !== 200) {
            ErrorAlert('Algo deu errado!', data.message);
        }
        return data;
    } catch (error) {
        return error;
    }
}
