var Swal = require("sweetalert2");

export const searchGuest = ({e, value, allGuest, setGuests, guest, changeFilter}) => {
    const textoPesquisa = e?.target.value;
    const textoPesquisaSemAcento = removerAcentos(textoPesquisa.toUpperCase());
    if (textoPesquisa.length >= 3) {
      if (value > 0) {
        let filtro;
        switch (value) {
          case 1:
            filtro = "pending";
            break;
          case 2:
            filtro = "approved";
            break;
          case 3:
            filtro = "disapproved";
            break;
          case 4:
            filtro = "confirm_presence";
            break;
          case 999:
              filtro = "telephone";
            break;
          default:
            return;
        }

        if (filtro === "confirm_presence") {
          const array = allGuest.filter((x) => {
            const nomeSemAcento = removerAcentos(x.name.toUpperCase());
            const telephoneSemChar = removerAcentos(x.telephone.toUpperCase());
            return (
              (nomeSemAcento.includes(textoPesquisaSemAcento) || telephoneSemChar?.includes(textoPesquisaSemAcento)) && x.confirm_presence === true
            );
          });
          setGuests(array);
        }

        if (filtro === "telephone") {
          const array = allGuest.filter((x) => {
            const nomeSemAcento = removerAcentos(x.name.toUpperCase());
            const telephoneSemChar = removerAcentos(x.telephone.toUpperCase());

            return (
              (nomeSemAcento.includes(textoPesquisaSemAcento) || telephoneSemChar?.includes(textoPesquisaSemAcento)) &&
              (x.telephone !== null || x.telephone !== '')
            );
          });
          setGuests(array);
        }

        if (value > 0 && value < 4) {
          const array = allGuest.filter((x) => {
            const nomeSemAcento = removerAcentos(x.name.toUpperCase());
            const telephoneSemChar = removerAcentos(x.telephone.toUpperCase());
            return (
              (nomeSemAcento.includes(textoPesquisaSemAcento) || telephoneSemChar?.includes(textoPesquisaSemAcento)) &&
              x.status === filtro
            );
          });
          setGuests(array);
        }

      } else {
        setGuests(
          allGuest.filter((x) => {
            const nomeSemAcento = removerAcentos(x.name.toUpperCase());
            const telephoneSemChar = removerAcentos(x?.telephone?.toUpperCase());
            return (nomeSemAcento.includes(textoPesquisaSemAcento) || telephoneSemChar?.includes(textoPesquisaSemAcento));
          })
        );
      }
    } else {
      if (textoPesquisa !== "") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "warning",
          title: "A consulta precisa incluir pelo menos 3 letras",
        });
      } else {
        if(value === 0){
        setGuests(allGuest);
      }else{
        changeFilter(value)
      }
      }
    }
  };

  const removerAcentos = (text) => {
    if(text){
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s/g, "");
    }
  };