// UserContext.js
import { useEffect } from "react";
import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState();
  const [type, setType] = useState();

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  const updateToken = (newToken) => {
    setToken(newToken);
  };

  const updateType = (newType) => {
    setType(newType);
  };

  useEffect(() => {
    if (JSON.stringify(localStorage).includes("@PuzliApp")) {
      let user = JSON.parse(atob(localStorage.getItem("@PuzliApp:main")));
      let token = JSON.parse(localStorage.getItem("@PuzliApp:token"));

      updateUser({ ...user, token: token });
      updateToken(token);
      updateType(user.type);
    }
  },[]);

  return (
    <UserContext.Provider
      value={{ user, token, type, updateUser, updateToken, updateType }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext deve ser usado dentro de um UserProvider");
  }
  return context;
};
