import React from "react";
import * as S from "../../styles";
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  CloseCircleTwoTone,
  FrownOutlined,
  QrcodeOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

const GuestStatus = ({ guest }) => {
  const style = {
    fontSize: "22px",
  };

  const checkStatus = (status) => {
    switch (status) {
      case "approved":
        return (
          <Tooltip title="Aprovado">
            <CheckCircleTwoTone style={style} twoToneColor={"#04cf29"} />
          </Tooltip>
        );
      case "pending":
        return (
          <Tooltip title="Pendente">
            <ClockCircleTwoTone style={style} />
          </Tooltip>
        );
      case "disapproved":
        return (
          <Tooltip title="Recusado">
            <CloseCircleTwoTone style={style} twoToneColor={"#bf1b1b"} />
          </Tooltip>
        );
      default:
        return;
    }
  };

  return (
    <S.StatusContainer>
      {guest?.is_verified === true ? <Tooltip title="QR Lido"><QrcodeOutlined style={{...style, color: "#5209a8"}} /></Tooltip> : ''}
      {checkStatus(guest?.status)}
      {guest.confirm_presence === false ? (
        <Tooltip title="Não comparecerá">
          <FrownOutlined style={{...style, color:"#A41B38"}} />
        </Tooltip>
      ) : (
        guest.confirm_presence === true ?
        (<Tooltip title="Confirmou presença">
          <SmileOutlined style={{...style, color:"#FFAA20"}} />
        </Tooltip>) :(
          <Tooltip title="Não respondeu">
          <FrownOutlined style={{...style, color:"rgba(0, 0, 0, 0.3)"}} />
        </Tooltip>
        )
      )}
    </S.StatusContainer>
  );
};

export default GuestStatus;
