import React from "react";
import * as S from "../../../styles";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

const ActionGrid = ({ print, guest, showButton }) => {
  return (
    <S.ActionContainer>
      {showButton &&
        <Button shape="circle" onClick={() => print([guest.id])}>
          <PrinterOutlined />
        </Button>
      }
    </S.ActionContainer>
  );
};

export default ActionGrid;
