import React from "react";
import * as S from "../../styles";
import OrderComp from "./components/OrderComp";
import FilterComp from "./components/FilterComp";
import SearchComp from "./components/SearchComp";
import DataInfoComp from "./components/DataInfoComp";
import ButtonGrid from "./components/ButtonGrid";

const OptionsGrid = ({
  indeterminate,
  onCheckAllChange,
  checkAll,
  orderList,
  onChangeFiltro,
  value,
  searchGuest,
  guest,
  allGuest,
  checked,
  setAllGuests,
  event,
  getGuests,
  print,
  baixarConvites,
}) => {
  return (
    <>
      <S.OptionsGrid>

        <S.GuestContainerSection style={{alignItems:'center', paddingTop:'20px'}}>
        <OrderComp orderList={orderList} />
          <ButtonGrid
            checked={checked}
            event={event}
            allGuest={allGuest}
            getGuests={getGuests}
            indeterminate={indeterminate}
            onCheckAllChange={onCheckAllChange}
            checkAll={checkAll}
            print={print}
            baixarConvites={baixarConvites}
          />
        </S.GuestContainerSection>

        <S.GuestContainerSection style={{justifyContent:'space-between'}}>
          <S.FilterContainer>
            <FilterComp
              onChange={onChangeFiltro}
              value={value}
              setAllGuests={setAllGuests}
            />
          </S.FilterContainer>
          <SearchComp searchGuest={searchGuest} />
        </S.GuestContainerSection>

        <DataInfoComp
          filtro={value}
          guest={guest}
          allGuest={allGuest}
          checkAll={checkAll}
          checked={checked}
        />
        
      </S.OptionsGrid>
    </>
  );
};

export default OptionsGrid;
