import "./App.css";
import MainRoutes from "./routes";
import { UserProvider } from "./context/userContext";
import { ConfigProvider } from "antd";
import { theme } from "./mainLayout/theme";

function App() {
  return (
    <UserProvider>
      <ConfigProvider theme={theme}>
        <MainRoutes />
      </ConfigProvider>
    </UserProvider>
  );
}

export default App;
