export const onChangeFiltro = ({valor, setGuests, allGuest, setValue, guest}) => {
    switch (valor) {
      case 0:
        filtroTodos({valor, setGuests, allGuest, setValue});
        break;
      case 1:
        filtroPendente({valor, setGuests, allGuest, setValue});
        break;
      case 2:
        filtroAprovado({valor, setGuests, allGuest, setValue});
        break;
      case 3:
        filtroRecusado({valor, setGuests, allGuest, setValue});
        break;
      case 4:
        filtroConfirmado({valor, setGuests, allGuest, setValue});
        break;
      case 5:
          filtroNegado({valor, setGuests, allGuest, setValue});
          break;
      case 6:
          filtroSemResposta({valor, setGuests, allGuest, setValue});
          break;
      case 999:
        somenteNumero({valor, setGuests, allGuest, setValue, guest});
        break;
      default:
        return;
    }
  };

  const filtroTodos = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest);
    setValue(0);
  };

  const filtroPendente = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest.filter((x) => x.status === "pending"));
    setValue(1);
  };

  const filtroAprovado = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest.filter((x) => x.status === "approved"));
    setValue(2);
  };

  const filtroRecusado = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest.filter((x) => x.status === "disapproved"));
    setValue(3);
  };
  const filtroConfirmado = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest.filter((x) => x.confirm_presence === true));
    setValue(4);
  };

  const filtroNegado = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest.filter((x) => x.confirm_presence === false));
    setValue(5);
  };

  const filtroSemResposta = ({valor, setGuests, allGuest, setValue}) => {
    setGuests(allGuest.filter((x) => x.confirm_presence === null));
    setValue(6);
  };

  const somenteNumero = ({valor, setGuests, allGuest, setValue, guest}) => {
    setValue(999);
    setGuests(guest.filter((x) => x.telephone != null));
  };
