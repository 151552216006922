// store/print/actions.js
export const SET_PRINT_DATA = 'SET_PRINT_DATA';
export const SET_PRINT_SIZE = 'SET_PRINT_SIZE';

export const setPrintData = (data) => ({
  type: SET_PRINT_DATA,
  payload: data,
});

export const setPrintSize = (data) => ({
  type: SET_PRINT_SIZE,
  payload: data
})