import { useState, useEffect, useCallback } from "react";
import * as S from "./styles";
import { getAllEvents } from "../../services/events";
import { useDispatch, useSelector } from "react-redux";
import EventGallery from "./components/EventGallery";
import { Heading } from "../../components/Heading";
import {setAllEvents} from "../../store/modules/events/actions";
import { logoutAuth } from '../../store/modules/user/actions';
import {Navigate} from 'react-router-dom'

export default function EventsScreen() {
  const { user } = useSelector((state) => state?.user?.user || {});
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState({
    main: [],
  });
  const dispatch = useDispatch();

  const getEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllEvents(user?.id, user?.type);
      if (response?.result !== undefined) {
        setEvents(response?.result?.list);
        dispatch(setAllEvents(response?.result?.list));
      } else {

        if(response?.response?.status === 401){
          dispatch(logoutAuth());
          <Navigate to="/login" replace={true}/>
        }

        setEvents({
          main: [],
        });
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  }, [user?.id, user?.type, dispatch]);

  useEffect(() => {
    setLoading(true);
    getEvents();
  }, [getEvents]);

  const verificaQuantidade = (eventos) => {
    let filtrados = eventos.filter(x => x.blocked_at == null && x.deleted_at ==null);
    let resultado = filtrados.find(x => !events.main.some(y => y.id === x.id));
    return resultado === undefined ? [] : [resultado];
  }

  return (
    <>
      {!loading && events.main && events.main.length > 0 && (
        <>
          <S.EventHeader>
            <Heading color="gray" size="large" marginRight={27}>
              Favoritos
            </Heading>
          </S.EventHeader>
          <EventGallery
            events={events.main}
            getEvents={getEvents}
            allEventsList={events}
          />
        </>
      )}

      {Object.keys(events).map(
        (key, event) =>
          key !== "main" &&
          verificaQuantidade(events[key]).length > 0 && (
            <S.GalleryContainer key={key}>
              <S.EventHeader>
                <Heading color="gray" size="large" marginRight={0}>
                  {key}
                </Heading>
              </S.EventHeader>
              <EventGallery
                events={events[key].filter(x => !events.main.some(y => y.id === x.id))}
                getEvents={getEvents}
                allEventsList={events}
              />
            </S.GalleryContainer>
          )
      )}
    </>
  );
}
