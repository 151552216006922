import { Button } from "antd";
import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
`

export const ApprovedButton = styled(Button)`
    background-color: #0E9D54;
    height: 45px;

    &&:hover{
        background-color: #008000 !important;
    }
`;

export const ReprovedButton = styled(Button)`
    background-color: #E63D32;
    height: 45px;

    &&:hover{
        background-color: #C62C2C !important;
    }
`;

export const PendingButton = styled(Button)`
    background-color: #33C3D0;
    height: 45px;

    &&:hover{
        background-color: #13aab8 !important;
    }
`;
