import { Button, Modal, Progress } from "antd";
import React from "react";
import * as S from "../../../styles";

const ModalCarregamento = ({
  visibility,
  setVisibility,
  checked,
  increment,
  guest,
  handleOk,
  messageEnvio,
  loading,
  totalCount
}) => {
  const setPercent = (valorParcial, valorTotal) => {
    if (
      typeof valorParcial !== "number" ||
      typeof valorTotal !== "number" ||
      valorTotal <= 0
    ) {
      return null;
    }

    const porcentagem = (valorParcial / valorTotal) * 100;

    return porcentagem;
  };

  return (
    <Modal
      centered
      className="modal-carregamento"
      open={visibility}
      onCancel={() => setVisibility(false)}
      destroyOnClose={true}
      maskClosable={true}
      footer={
        <Button
          type="primary"
          onClick={handleOk}
          disabled={increment !== (checked?.length || totalCount)}
        >
          Ok
        </Button>
      }
    >
      <S.ProgressContainer>
        <div>Convidado: {guest?.name}&nbsp;</div>
        <div style={{ display: "flex" }}>
          <Progress
            percent={setPercent(increment, checked?.length)}
            showInfo={false}
          />{" "}
          <div style={{ display: "flex", fontWeight: "600", width: "30px" }}>
            {increment}/{totalCount ?? checked?.length}
          </div>
        </div>
        {loading === undefined && messageEnvio === undefined ? '' : <div
          style={{
            minHeight: "50px",
            backgroundColor: "lightgrey",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          {(increment === 0 ? '' : (messageEnvio !== "alterar-status-guest" ? (messageEnvio ? <div dangerouslySetInnerHTML={{ __html: messageEnvio}}/> : <span style={{color:'rgba(0,0,0,0.4)'}}>Nenhuma mensagem foi escrita.</span>) : ''))}
        </div>}
      </S.ProgressContainer>
    </Modal>
  );
};

export default ModalCarregamento;
