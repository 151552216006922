import reducer from './reducers';
import { loginAuth, logoutAuth } from './actions';

const actions = {
    loginAuth,
    logoutAuth,
};

const exportedModule = { actions, reducer };

export default exportedModule;
