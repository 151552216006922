import { Radio, Space } from "antd";
import React from "react";

const FilterComp = ({onChange, value, setAllGuests}) => {
  const style = {
    display: 'flex',
    flexWrap:'wrap',
    justifyContent: 'center',
    gap:'7px'
  }
  const radioStyle = {
    minWidth: '120px',
    maxWidth: '144px'
  }
  return (
  <Radio.Group style={style} onChange={onChange} value={value} className="radio-group-comp">
    <Space style={{justifyContent:'left'}}>
    <Space direction="vertical" style={{minWidth:'155px'}}>
    <Radio style={radioStyle} value={0} className="radio-child">Todos</Radio>
    <Radio style={radioStyle} value={1} className="radio-child">Pendente</Radio>
    <Radio style={radioStyle} value={2} className="radio-child">Aprovado</Radio>
    <Radio style={radioStyle} value={3} className="radio-child">Reprovado</Radio>
    </Space>
    
    <Space direction="vertical" style={{minWidth:'155px'}}>
    <Radio style={radioStyle} value={4} className="radio-child">Comparecerá</Radio>
    <Radio style={radioStyle} value={5} className="radio-child">Não Comparecerá</Radio>
    <Radio style={radioStyle} value={6} className="radio-child">Sem Resposta</Radio>
    <Radio style={radioStyle} value={999} className="radio-child">C/Telefone</Radio>
    </Space>
    </Space>
  </Radio.Group>
  );
};

export default FilterComp;
