import React from 'react'
import { Event } from './Event';
import * as S from '../styles'

const EventGallery = ({events, allEventsList}) => {
    const isFavoriteInCategory = (item) => {
        const itemExistsInFavorites = allEventsList?.main.find(
            (event) => event.id === item.id,
        );
        return itemExistsInFavorites;
    };

  return (
    <S.Container>
        {events?.map((event) => (
        event.blocked_at === null && event.deleted_at === null ?
        <Event
            key={event.id}
            event={event}
            allEventsList={allEventsList}
            isFavoriteInCategory={isFavoriteInCategory}
        /> : ''
    ))}
    </S.Container>
  )
  
}

export default EventGallery