export const getToken = async () => {
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem('@PuzliApp:token');

            if (token) {
                const tokenFormat = JSON.parse(token);
                resolve(tokenFormat);
            } else {
                resolve(null); // ou você pode rejeitar com uma mensagem de erro, se preferir
            }
        } catch (error) {
            reject(error);
        }
    });
}

export default getToken;
