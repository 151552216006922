import React from "react";
import MainLayout from "../../mainLayout";
import Pages from "../Pages";

export const Home = () => {

  return (
    <>
    <MainLayout>
    <Pages/>
    </MainLayout>
    </>
  );

};

export default Home;
