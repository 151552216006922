// store/print/reducers.js
import { SET_ALL_EVENTS } from './actions';

const initialState = {
  eventData: [],
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_EVENTS:
      return {
        ...state,
        eventData: action.payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
