import axios from "axios";

export const enviarLista = async (event, message, guest) => {
  const phone = await verificarNumero(
    event,
    guest.telephone.replace(/[^\d]/g, "")
  );

  var telefone;
  if (phone === null || phone === undefined || phone?.exists === false) {
    telefone = await verificarNumero(
      event,
      `55${guest.telephone.replace(/[^\d]/g, "")}`
    );
  }

  if (telefone?.exists === false && phone?.exists === false) {
    return { error: "Telefone Inválido" };
  }

  const body = JSON.stringify({
    phone: phone?.phone === null ? telefone?.phone : phone?.phone,
    message: message,
    optionList: {
      title: " ",
      buttonLabel: "Clique para confirmar presença",
      options: [
        {
          id: guest.id,
          description: "",
          title: "Sim",
        },
        {
          id: guest.id,
          description: "",
          title: "Não",
        },
      ],
    },
  });

  try {
    const response = await axios.request({
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL.replace(
        "_id_",
        event.zapi_instance
      ).replace("_token_", event.zapi_token)}send-option-list`,
      headers: {
        "Client-Token": process.env.REACT_APP_CLIENT_ID,
        "Content-Type": "application/json",
      },
      data: body,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const listaNumeros = async (event, guestPhone) => {
  if(guestPhone == null || guestPhone == ""){
    return {retorno: "Sem telefone" };
  }

  var telefone = await verificarNumero(
      event,
      `55${guestPhone.replace(/[^\d]/g, "")}`
    );

  const phone = await verificarNumero(
    event,
    guestPhone.replace(/[^\d]/g, "")
  );


  if (telefone?.exists === false && phone?.exists === false) {
    return {retorno: "Telefone INVÁLIDO" };
  }else if(telefone?.exists === false && phone?.exists === true){
    return {retorno: "Telefone Internacional"}
  }else{
    return {retorno: "Telefone Válido"}
  }
}

export const verificarNumero = async (event, phone) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL.replace(
        "_id_",
        event.zapi_instance
      ).replace("_token_", event.zapi_token)}phone-exists/${phone}`,
      {
        headers: {
          "Client-Token": process.env.REACT_APP_CLIENT_ID,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const verificarNumeroLote = async (event, phones) => {
  try {

    const obj = {
      phones: phones
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL.replace(
        "_id_",
        event.zapi_instance
      ).replace("_token_", event.zapi_token)}phone-exists-batch`,
      obj,
      {
        headers: {
          "Client-Token": process.env.REACT_APP_CLIENT_ID,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
