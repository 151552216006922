import styled, { css } from 'styled-components';
import { defaultTheme as theme } from '../../styles/theme';

const Wrapper = styled.div`
    ${({ color, size = 'medium', marginBottom, marginTop, marginRight }) => css`
        color: ${theme.colors[color || 'black']};
        font-family: 'Roboto';
        font-weight: 600;
        margin-bottom: ${marginBottom}px;
        margin-top: ${marginTop}px;
        margin-right: ${marginRight}px;
        display: flex;
        font-size: ${theme.font.sizes[size]};
    `}
`;

export { Wrapper };