// store/print/index.js
import * as actions from './actions';
import printReducer from './reducers';

export {printReducer}

const exportedModule = { actions, printReducer };

export default exportedModule;


