import { Button, Input, Modal } from "antd";
import React from "react";

const ModalMensagem = ({
  visible,
  setVisible,
  setMessage,
  message,
  sendList,
  handleOk,
  loading,
}) => {
  return (
    <Modal
    centered
      open={visible}
      onCancel={() => handleOk()}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "12px",
            fontSize: "12px",
          }}
        >
          Use "_name_" para enviar uma mensagem com o nome do convidado.
        </div>,
        <Button key="back" onClick={() => handleOk()}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={sendList}>
          Enviar
        </Button>,
      ]}
    >
      <Input.TextArea
        style={{ marginTop: "25px", height: "250px" }}
        onChange={(e) => setMessage(e?.target?.value)}
        value={message}
        defaultValue={message}
        
      />
    </Modal>
  );
};

export default ModalMensagem;
