export const orderList = ({e, guest, setGuests}) => {
    switch (e) {
      case "1":
        return sortAZ({e, guest, setGuests});
      case "2":
        return sortZA({e, guest, setGuests});
      case "3":
        return date0_1({e, guest, setGuests});
      case "4":
        return date1_0({e, guest, setGuests});
      default:
        return;
    }
  };

  const sortAZ = ({e, guest, setGuests}) => {
    const orderAZ = [...guest].sort((a, b) => {
      const nomeA = a.name.toUpperCase();
      const nomeB = b.name.toUpperCase();

      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }

      // Nomes são iguais
      return 0;
    });
    setGuests(orderAZ);
  };

  const sortZA = ({e, guest, setGuests}) => {
    const orderAZ = [...guest].sort((b, a) => {
      const nomeA = a.name.toUpperCase();
      const nomeB = b.name.toUpperCase();

      if (nomeA < nomeB) {
        return -1;
      }
      if (nomeA > nomeB) {
        return 1;
      }

      // Nomes são iguais
      return 0;
    });
    setGuests(orderAZ);
  };

  const date0_1 = ({e, guest, setGuests}) => {
    const orderCreatedAt = [...guest].sort((a, b) => {
      const dataA = new Date(a.created_at);
      const dataB = new Date(b.created_at);

      // Use a lógica de comparação de datas
      if (dataA < dataB) {
        return -1;
      }
      if (dataA > dataB) {
        return 1;
      }

      // Datas são iguais
      return 0;
    });
    setGuests(orderCreatedAt);
  };

  const date1_0 = ({e, guest, setGuests}) => {
    const orderCreatedAt = [...guest].sort((b, a) => {
      const dataA = new Date(a.created_at);
      const dataB = new Date(b.created_at);

      // Use a lógica de comparação de datas
      if (dataA < dataB) {
        return -1;
      }
      if (dataA > dataB) {
        return 1;
      }

      // Datas são iguais
      return 0;
    });
    setGuests(orderCreatedAt);
  };