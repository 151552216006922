import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Button, Alert, Row, Col } from "antd";
import { useUserContext } from "../../../context/userContext";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { loginAuth } from "../../../store/modules/user/actions";
import { Box, Container, Stack } from "@chakra-ui/react";
import Logo from "../../../assets/images/login/marca_logo3.png";
import { api } from "../../../services/api";
import { useDispatch } from "react-redux";

const Login = () => {
  // const user = useSelector((state) => state?.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const { updateUser, updateToken, updateType } = useUserContext();

  const { user } = useUserContext();
  const isLoggedIn = Object.keys(user).length > 0;

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorAlert, setErrorAlert] = useState("");

  // const inputRef = useRef(null);

  const isAuthenticated = JSON.stringify(localStorage).includes("@PuzliApp:token");

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Adiciona um efeito de rolagem suave (opcional)
    });
  }

  const handleInputFocus = () => {
    scrollToTop();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!username || !password) return;

      const sessionId = uuid();

      const result = await api.post("/clients/token", {
        email: username,
        password,
        session: sessionId,
      });

      const response = result;
      const messageError = response.data.message;
      if (
        ["Digite corretamente seu email.", "Email inválido"].includes(
          messageError
        )
      ) {
      } else if (
        [
          "Senha inválida.",
          "Digite corretamente sua senha de pelo menos 6 dígitos.",
        ].includes(messageError)
      ) {
      }

      const object = {
        token: response.data.result.token,
        refreshToken: response.data.result.tokenRefresh,
        user: response.data.result.user,
      };
      dispatch(loginAuth(object));

      updateUser({ ...response.data.result.user, token: object.token });
      updateType(response.data.result.user.type);
      updateToken(object.token);

      localStorage.setItem(
        "@PuzliApp:client",
        JSON.stringify(response.data.result.user.client || "")
      );
      localStorage.setItem(
        "@PuzliApp:type",
        JSON.stringify(response.data.result.user.type)
      );
      localStorage.setItem("@PuzliApp:token", JSON.stringify(object.token));
      localStorage.setItem(
        "@PuzliApp:session",
        JSON.stringify(
          response.data.result.user.client === undefined
            ? ""
            : response.data.result.user.client[0]?.session
        )
      );
      localStorage.setItem(
        "@PuzliApp:main",
        btoa(
          JSON.stringify(
            response.data.result.user === undefined
              ? ""
              : response.data.result.user
          )
        )
      );
      localStorage.setItem(
        "@PuzliApp:refresh_token",
        JSON.stringify(object.refreshToken)
      );
      document.body.style.overflow = 'auto'
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1');
      window.document.body.style.zoom = 1;
      wrapperRef.current.style.zoom = "90%";

      localStorage.removeItem("hasReloaded");
      navigate("/home");
    } catch (err) {
      setLoading(false);
      setErrorAlert(
        <Row>
          <Col></Col>
          <Col>
            <Alert message={err?.data?.message} type="warning"></Alert>
          </Col>
        </Row>
      );
    }
  };


  useEffect(() => {
    document.body.style.overflow = 'hidden'
  },[])

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded') === 'true' ? true : false;

    if (!hasReloaded) {
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setErrorAlert();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorAlert();
  };

  useEffect(() => {
    if (!isAuthenticated || !isLoggedIn) {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    } else {
      navigate("/home");
    }
  }, [isAuthenticated, navigate, isLoggedIn]);


  return (
    !isAuthenticated && (
      <>
        <div className="area" ref={wrapperRef}>
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <Container
            className="login-main-container"
            maxW="lg"
            py={{ base: "12", md: "24" }}
            px={{ base: "0", sm: "8" }}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              justifyContent: "center",
              fontFamily: "Roboto",
            }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "60px",
              }}
              className="login-logo-container"
            >
              <img
                className="login-logo"
                src={Logo}
                style={{ height: "200px", zIndex: "999" }}
                alt="fireSpot"
              />
            </Row>
            <Stack
              spacing="8"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                w={"50%"}
                py={{ base: "4", sm: "8" }}
                px={{ base: "4", sm: "10" }}
                bg={{ base: "transparent", sm: "bg-surface" }}
                boxShadow={{
                  base: "none",
                  sm: "0 0px 20px -12px rgba(0, 0, 0, 0.60)",
                }}
                borderRadius={{ base: "2xl", sm: "2xl" }}
                backgroundColor="rgba(255, 255, 255, 0.15)"
                className="login-box"
                style={{
                  padding: "25px",
                  backdropFilter: "blur(5.6px)",
                  WebkitBackdropFilter: "blur(5.6px)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  paddingBottom: "10px",
                  zIndex: "999",
                  borderRadius: "12px",
                }}
                paddingBottom={"0px"}
              >
                <Stack spacing="6">
                  <Form onSubmit={handleSubmit} layout="vertical">
                    <Form.Item
                      
                      label="Usuário"
                      name="username"
                      value={username}
                      onChange={handleUsernameChange}
                      rules={[
                        {
                          required: true,
                          message: "Insira seu nome de usuário!",
                        },
                      ]}
                    >
                      <Input onBlur={handleInputFocus} />
                    </Form.Item>

                    <Form.Item
                      label="Senha"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      rules={[
                        {
                          required: true,
                          message: "Insira sua senha!",
                        },
                      ]}
                    >
                      <Input.Password onBlur={handleInputFocus} style={{touchAction:'none'}}/>
                    </Form.Item>

                    <Form.Item>
                      <Row
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="submit-login"
                          type="primary"
                          htmlType="submit"
                          onClick={handleSubmit}
                          style={{
                            width: "50%",
                            marginTop: "30px",
                            height: "38px",
                          }}
                          loading={loading}
                          disabled={!username || !password}
                        >
                          Login
                        </Button>
                      </Row>
                    </Form.Item>
                  </Form>
                </Stack>
              </Box>
            </Stack>
            <Container
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                minHeight: "50px"
              }}
            >
              {errorAlert}
            </Container>
          </Container>
        </div>
      </>
    )
  );
};

export default Login;
