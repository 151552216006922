import * as XLSX from 'xlsx';

export const gerarRelatorio = (allGuest, event) => {


   let objRelatorio = ({})
   
   objRelatorio = allGuest.map((item) => {
        return {
            Nome: item.name,
            Telefone: item.telephone,
            "Confirmou Presença?": GetStatus(item.confirm_presence)
        }
    });

    let contagem = ([{
        "TOTAL": allGuest.length,
        "CONFIRMARAM": allGuest.filter((x) => x.confirm_presence === true).length,
        "RECUSARAM": allGuest.filter((x) => x.confirm_presence === false).length,
        "NÃO RESPONDERAM": allGuest.filter((x) => x.confirm_presence === null).length,
    }])

    const wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(contagem);
    let ws2 = XLSX.utils.json_to_sheet(objRelatorio);
    XLSX.utils.book_append_sheet(wb, ws2, 'Relatório');
    XLSX.utils.book_append_sheet(wb, ws, 'Quantidades');

    ws['!cols'] = [
        { width: 15 },   // Largura da primeira coluna
        { width: 15 },   // Largura da segunda coluna
        { width: 15 },   // Largura da segunda coluna
        { width: 18 }    // Largura da segunda coluna
    ];

    ws2['!cols'] = [
        { width: 30 },  // Largura da primeira coluna
        { width: 15 },   // Largura da segunda coluna
        { width: 18 },   // Largura da segunda coluna
    ];

    let nomeRelatorio = `relatorio_${formatString(event?.name)}_${formatDate()}.xslx`;

    if(nomeRelatorio.length > 35)
    {
        nomeRelatorio = `relatorio_${formatDate()}.xlsx`
    }
    XLSX.writeFile(wb, nomeRelatorio);

}

function GetStatus(status){
    switch(status){
        case true:
            return "SIM"
        case false:
            return "NÃO COMPARECERÁ"
        case null:
            return "-"
        default:
            return;
    }
}

function formatString(inputString) {
    const stringWithoutSpecialChars = inputString.replace(/[^\w\s]/gi, '');
    const formattedString = stringWithoutSpecialChars.replace(/\s+/g, '');

    return formattedString;
}

function formatDate() {
    const currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}_${hours}${minutes}`;

    return formattedDate;
}