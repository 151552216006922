import { Button, Modal } from "antd";
import React, { useState } from "react";
import {
  ApprovedButton,
  ReprovedButton,
  PendingButton,
  ButtonsContainer,
} from "./style";
import Swal from "sweetalert2";
import {
  alterarPresenca,
  statusChange,
} from "../../../../../../services/changeStatus";
import ModalCarregamento from "../ModalCarregamento";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ModalChangeStatus = ({
  changeStatusVisibility,
  setChangeStatusVisibility,
  checked,
  allGuest,
  getGuests,
}) => {
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [visibilityCarregamento, setVisibilityCarregamento] = useState(false);
  const [increment, setIncrement] = useState(0);
  const [guest, setGuest] = useState();
  const [loading, setLoading] = useState(false);

  const confirmAction = (type) => {
    var message;
    switch (type) {
      case "approved":
        message = `<div style='display:flex;align-items: center; flex-direction:column'>Deseja aprovar os convidados selecionados? <br/>
        <span style='margin-top:12px;display:flex;width: fit-content;font-size:14px; background: antiquewhite; padding: 5px; border-radius: 8px;'>ATENÇÃO: Essa ação <strong>&nbsp;não&nbsp;</strong> envia convites!</span>`;
        break;
      case "disapproved":
        message = "Deseja reprovar os convidados selecionados?";
        break;
      case "pending":
        message = "Deseja colocar os convidados selecionados em espera?";
        break;
      case "confirmation":
        openConfirmacao();
        break;
      default:
        break;
    }

    if (type !== "confirmation") {
      Swal.fire({
        text: message,
        showDenyButton: true,
        html: message,
        confirmButtonText: "Sim",
        denyButtonText: "Não",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          changeStatus(type);
        } else if (result.isDenied) {
        }
      });
    }
  };

  const changeStatus = async (status) => {
    setVisibilityCarregamento(true);

    for (var i = 0; i < checked.length; i++) {
      var id = checked[i];
      const guestObj = allGuest.find((x) => x.id === id);

      setGuest(guestObj);

      await statusChange(status, id).then((result) => {
        setIncrement(i + 1);
      });
    }
    getGuests();
  };

  const openConfirmacao = () => {
    setVisibleConfirmation(true);
  };

  const confirmarPresenca = async () => {
    setVisibilityCarregamento(true);

    for (var i = 0; i < checked.length; i++) {
      var id = checked[i];
      var status = true;
      const guestObj = allGuest.find((x) => x.id === id);

      setGuest(guestObj);

      await alterarPresenca(id, status).then((result) => {
        setIncrement(i + 1);
      });
    }
    getGuests();
  };

  const recusarPresenca = async () => {
    setLoading(true);
    setVisibilityCarregamento(true);

    for (var i = 0; i < checked.length; i++) {
      (async function (index) {
        var id = checked[index];
        var status = false;
        const guestObj = allGuest.find((x) => x.id === id);

        setGuest(guestObj);

        try {
          await alterarPresenca(id, status).then((result) => {
            setIncrement(index + 1);
          });
        } catch (error) {}
      })(i);
    }

    setLoading(false);
    getGuests();
  };

  const handleOk = () => {
    setGuest();
    setIncrement(0);
    setVisibilityCarregamento(false);
  };

  return (
    <Modal
      onCancel={() => setChangeStatusVisibility(false)}
      open={changeStatusVisibility}
      width={250}
      centered
      closeIcon={false}
      footer={null}
    >
      <ButtonsContainer>
        <ApprovedButton
          type="primary"
          onClick={() => confirmAction("approved")}
        >
          Aprovado
        </ApprovedButton>

        <ReprovedButton
          type="primary"
          onClick={() => confirmAction("disapproved")}
        >
          Reprovado
        </ReprovedButton>

        <PendingButton type="primary" onClick={() => confirmAction("pending")}>
          Em Espera
        </PendingButton>

        <Button
          type="primary"
          style={{ height: "45px" }}
          onClick={() => confirmAction("confirmation")}
        >
          Confirmação de Presença
        </Button>
      </ButtonsContainer>

      <ButtonsContainer style={{ alignItems: "end", marginTop: "2rem" }}>
        <Button
          onClick={() => setChangeStatusVisibility(false)}
          style={{ width: "50%" }}
        >
          Cancelar
        </Button>
      </ButtonsContainer>

      <Modal
        onCancel={() => setVisibleConfirmation(false)}
        open={visibleConfirmation}
        closeIcon={false}
        footer={null}
        width={300}
        centered
      >
        <ButtonsContainer>
          Selecione o novo status de presença:
          <ApprovedButton type="primary" onClick={() => confirmarPresenca()}>
            Confirmar Presença
          </ApprovedButton>
          <ReprovedButton type="primary" onClick={() => recusarPresenca()}>
            Recusar Presença
          </ReprovedButton>
        </ButtonsContainer>

        <ButtonsContainer style={{ alignItems: "end", marginTop: "2rem" }}>
          <Button
            onClick={() => setVisibleConfirmation(false)}
            style={{ width: "50%" }}
          >
            Cancelar
          </Button>
        </ButtonsContainer>
      </Modal>

      <ModalCarregamento
        checked={checked}
        visibility={visibilityCarregamento}
        setVisibility={setVisibilityCarregamento}
        increment={increment}
        guest={guest}
        handleOk={handleOk}
      />
    </Modal>
  );
};

export default ModalChangeStatus;
