import { LOGIN, LOGOUT } from './constants'

export const logoutAuth = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
        payload: undefined
    });
};

export const loginAuth = (user) => (dispatch) => {
    dispatch({
        type: LOGIN,
        payload: user,
    });
};

