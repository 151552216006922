import React, { useState, useEffect } from "react";
import { Drawer, Tooltip } from "antd";
import { StyledSpace, StyledButton, StyledButtonBack, StyledButtonLogout } from "./style";
import { LogoutOutlined, MenuOutlined, StepBackwardOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutAuth } from '../../store/modules/user/actions';
import { useDispatch, useSelector } from "react-redux";

const Navigation = () => {

  const [open, setOpen] = useState(false);
  const placement = 'left';
  // const [placement, setPlacement] = useState("left");
  // let  params = useParams();
  let location = useLocation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [show, setShow] = useState(false);

  const local = location.pathname;

  const navigate = useNavigate();


  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const voltar = () => {

    if(local !== '/home'){
      navigate(-1);
    }
  }

  useEffect(() => {
    if(location.pathname !== '/login' && location.pathname !== '/print'){
      setShow(user !== undefined && user !== null ? true : false);
    }else{
      setShow(false);
    }
  },[location, user]);

  return (
    <>
     {show ?
     <>
     <StyledSpace>
        
        <Tooltip title="Menu">
        <StyledButton type="primary" onClick={showDrawer}><MenuOutlined style={{fontSize:'20px'}} /></StyledButton>
        </Tooltip>

        {local !== '/home'  && local !== '/' ? <Tooltip title="Voltar">
        <StyledButtonBack onClick={voltar}>
          <StepBackwardOutlined />
        </StyledButtonBack> 
        </Tooltip> : ''}

      </StyledSpace>
      <Drawer
        title="Menu"
        placement={placement}
        closable={true}
        onClose={onClose}
        open={open}
        key={placement}
      
      >
        <p></p>
        <Link to="/login" onClick={(e) => {dispatch(logoutAuth()); setOpen(false)}}><StyledButtonLogout><span>Logout</span> <LogoutOutlined /></StyledButtonLogout></Link>
      </Drawer>
      </>:''}
    </>
  );
};

export default Navigation;
