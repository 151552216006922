import { Button, Form, InputNumber, Modal, Radio, Slider } from "antd";
import React, { useState } from "react";
import EtiquetaPreview from "./EtiquetaPreview";

const ModalEtiqueta = ({ visibilityPrint, setVisibilityPrint, printSize }) => {
  const initialStates = 
    {
      qrcode: {
        up: 0,
        left: 0,
        down: 0,
        right: 0,
      },
      textLocation: 0,
      qrCodeSize: 100,
      alturaInit: 30,
      larguraInit: 80,
      fontInit: 16
    };

  const [altura, setAltura] = useState(initialStates.alturaInit);
  const [largura, setLargura] = useState(initialStates.larguraInit);
  const [fontSize, setFontSize] = useState(initialStates.fontInit);
  const [qrCodeSize, setQrCodeSize] = useState(initialStates.qrCodeSize);
  const [qrCodeLocation, setQrCodeLocation] = useState(initialStates.qrcode);
  const [textLocation, setTextLocation] = useState(initialStates.textLocation);
  const [form] = Form.useForm();

  const validate = (e) => {
    e.preventDefault();

    if (!altura || !largura) {
      return;
    } else {
      printSize({
        height: altura,
        width: largura,
        fontSize: fontSize,
        qrCodeLocation: qrCodeLocation,
        qrCodeSize: qrCodeSize,
        textLocation: textLocationParser(textLocation),
      });
      setAltura(initialStates.alturaInit);
      setLargura(initialStates.larguraInit);
      setFontSize(initialStates.fontInit);
      setQrCodeSize(initialStates.qrCodeSize);
      setTextLocation(initialStates.textLocation);
      setQrCodeLocation(initialStates.qrcode);
    }
  };

  const textLocationParser = (value) => {
    switch (value) {
      case 0:
        return "left";
      case 1:
        return "center";
      case 2:
        return "right";
      default:
        return;
    }
  };

  const cancel = (e) => {
    e.preventDefault();
    setAltura(initialStates.alturaInit);
    setLargura(initialStates.larguraInit);
    setFontSize(initialStates.fontInit);
    setQrCodeSize(initialStates.qrCodeSize);
    setTextLocation(initialStates.textLocation);
    setQrCodeLocation(initialStates.qrcode);
    setVisibilityPrint(false);
  };

  return (
    <Modal
      open={visibilityPrint}
      onCancel={(e) => {
        cancel(e);
      }}
      afterClose={form.resetFields}
      footer={[]}
      width='fit-content'
    >
      Escreva em milimetros a altura e largura da etiqueta.
      <Form form={form} style={{ marginTop: "30px" }} onFinish={printSize}>
        <Form.Item
          label="Altura"
          name="height"
          rules={[
            {
              required: true,
              message: "Altura é obrigatória!",
            },
          ]}
        >
          <InputNumber
          value={altura}
          defaultValue={altura}
            onChange={(e) => setAltura(e)}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Largura"
          name="width"
          rules={[
            {
              required: true,
              message: "Largura é obrigatória!",
            },
          ]}
        >
          <InputNumber
            value={largura}
            defaultValue={largura}
            onChange={(e) => setLargura(e)}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Fonte (em px)"
          name="fontSize"

          rules={[
            {
              required: true,
              message: "Tamanho da font é obrigatória!",
            },
          ]}
        >
          <InputNumber
            value={fontSize}
            defaultValue={fontSize}
            onChange={(e) => setFontSize(e)}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="QRCode (em %)"
          name="qrCodeSize"
          rules={[
            {
              required: true,
              message: "Tamanho da qrCode é obrigatório!",
            },
          ]}
        >
          <InputNumber
            min={1}
            max={1000}
            defaultValue={100}
            onChange={(e) => setQrCodeSize(e)}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Localização do Texto"
          name="textLocation"
          rules={[
            {
              required: true,
              message: "Tamanho da font é obrigatória!",
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => setTextLocation(e.target.value)}
            defaultValue={textLocation}
            value={textLocation}>
            <Radio value={0}>Esquerda</Radio>
            <Radio value={1}>Centro</Radio>
            <Radio value={2}>Direita</Radio>
          </Radio.Group>
        </Form.Item>

        <div style={{ display: "flex" }}>
          <Form.Item
            className="qr-location"
            label="Localização do QR Code"
            name="qrCodeSize"
            rules={[
              {
                required: true,
                message: "Tamanho da qrCode é obrigatório!",
              },
            ]}
          >
            <span>
              Vertical (y):{" "}
              <InputNumber
                min={-(altura * 5)}
                max={altura * 5}
                style={{
                  margin: "0 16px",
                }}
                value={qrCodeLocation.up}
                defaultValue={qrCodeLocation.up}
                onChange={(e) =>
                  setQrCodeLocation({ ...qrCodeLocation, up: e })
                }
              />
              <Slider
                vertical
                reverse
                style={{ height: "100px" }}
                min={-(altura * 5)}
                max={altura * 5}
                defaultValue={qrCodeLocation.up}
                onChange={(e) =>
                  setQrCodeLocation({ ...qrCodeLocation, up: e })
                }
                value={
                  typeof qrCodeLocation.up === "number" ? qrCodeLocation.up : 0
                }
              />
            </span>
            <span>
              Horizontal (x):{" "}
              <InputNumber
                min={-(largura * 5)}
                max={largura * 5}
                style={{
                  margin: "0 16px",
                }}
                value={qrCodeLocation.left}
                defaultValue={qrCodeLocation.left}
                onChange={(e) =>
                  setQrCodeLocation({ ...qrCodeLocation, left: e })
                }
              />
              <Slider
                style={{ width: "200px" }}
                min={-(largura * 5)}
                max={largura * 5}
                defaultValue={qrCodeLocation.left}
                value={qrCodeLocation.left}
                onChange={(e) =>
                  setQrCodeLocation({ ...qrCodeLocation, left: e })
                }
              />
            </span>
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: "65px",
            display: "flex",
            justifyContent: "end",
            gap: "10px",
          }}
        >
          <Button type="primary" htmlType="submit" onClick={(e) => validate(e)}>
            Ok
          </Button>
        </div>
      </Form>
      <EtiquetaPreview
        altura={altura}
        largura={largura}
        fontSize={fontSize}
        qrCodeSize={qrCodeSize}
        qrCodeLocation={qrCodeLocation}
        textLocation={textLocationParser(textLocation)}
      />
    </Modal>
  );
};

export default ModalEtiqueta;
