import { getToken } from "./functions";
import { api } from "./api";
import { ErrorAlert } from "../components/ErrorAlert";
import axios from "axios";

export const alterarPresenca = async (id, status) => {
  try {
    const token = await getToken();
    const body = {
      idGuest: id,
      confirmPresence: false,
    };
    const { data } = await api.patch("guests/confirmPresence", body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    });
    if (data.status !== 200) {
      ErrorAlert("Algo deu errado", data.message);
    }
    return data;
  } catch (e) {
    return e;
  }
};

export const statusChange = async (status, id) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://comp.puzli.com.br/guest/status-change?status=${status}&id=${id}`,
  }
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }};
