import { applyMiddleware, combineReducers, compose, createStore  } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
import { actions, reducers } from './modules';
import storage from 'redux-persist/lib/storage';
import { printReducer } from './modules/print';
import {eventReducer} from './modules/events'

const persistConfig = {
    key: 'root',
    storage,
};

const middleware = [thunk];
const composer = compose(applyMiddleware(...middleware));
const pReducer = persistReducer(
    persistConfig,
    combineReducers({
      ...reducers, 
      print: printReducer,
      eventos: eventReducer
    }));
    
const store = createStore(pReducer, composer);
const persistor = persistStore(store);

export { actions, persistor, store };
export const RootState = store.getState;
export const AppDispatch = store.dispatch;